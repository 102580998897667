import { useState, useContext, useRef } from 'react';
import { useRouter } from 'next/router';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Script from 'next/script';

import { MainContext } from '@components/context/MainProvider';
import Meta from '@components/Meta';
import { LoginWrapper } from '../components/Login/LoginWrapper';
import Page1 from '@components/Login/Stack/Page1';
import Page2 from '@components/Login/Stack/Page2';
import Page3 from '@components/Login/Stack/Page3';
import Page4 from '@components/Login/Stack/Page4';
import Page5 from '@components/Login/Stack/Page5';
import { AppEnvStates } from '../../src/constants';
import { setUserInBraze } from '@nextUtils/brazeUtils';
import { useRedirectOnLogin } from '@hooks/useRedirectOnLogin';
import { getAPIUrl } from '@nextUtils/urlUtils';

const pageTransitionSpeed = 200;

/**
 * Pass nextPath as a query string to redirect the user after login
 *  i.e. www.secondnature.io/login?nextPath=restart
 */
const Login = (): JSX.Element => {
  const {
    query: { nextPath },
  } = useRouter();

  const context = useContext(MainContext);
  const { user } = context;

  const nodeRef = useRef(null);

  useRedirectOnLogin();

  const [formState, setFormState] = useState({
    email: '',
    page: 1,
  });

  const finishLoggingIn = async () => {
    const userResponse = await fetch(`${getAPIUrl()}/api/v2/user`);
    const { user: userData } = await userResponse.json();

    if (window.FS && user) {
      window.FS.identify(userData.email);
    }

    if (window.mixpanel?.identify) {
      window.mixpanel.identify(userData.email);
    }

    setUserInBraze(userData._id);

    if (window.ire) {
      window.ire('identify', { customerId: userData._id });
    }

    if (window.clarity) {
      window.clarity('identify', userData.email, null, null, userData.email);
    }

    // On setting a user, the user will be redirected
    // automatically by the useEffect() at the top
    context.setUser(userData);
  };

  const isRestarterFlow = nextPath?.includes('restart');

  return (
    <>
      <Meta
        title="Login | Second Nature"
        metaDescription="Log in to Second Nature"
        relativeCanonicalURL="/login"
      />
      {/* This loads the Facebook SDK lazily - for use when logging in via Facebook */}
      <Script
        id="load-facebook-sdk"
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js"
        strategy="lazyOnload"
        onLoad={() => {
          if (window.FB) {
            window.FB.init({
              appId:
                process.env.APP_ENV === AppEnvStates.PRODUCTION
                  ? '266842886827767'
                  : '1636860756655160', // Facebook App ID. In development, use the 'Second Nature - Development' Facebook App
              version: 'v19.0',
            });
          }
        }}
      />
      {/* This loads the Apple Sign-in library lazily - for use when logging in with an account via Apple */}
      <Script
        id="load-apple-signin"
        crossOrigin="anonymous"
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
        strategy="lazyOnload"
        onLoad={() => {
          if (window.AppleID && window.AppleID.auth) {
            window.AppleID.auth.init({
              clientId: 'com.secondnature.secondnature',
              scope: 'email',
              redirectURI: 'https://www.secondnature.io/apple-auth/callback',
              state: 'state',
              nonce: 'nonce',
              usePopup: true,
            });
          }
        }}
      />
      <LoginWrapper isRestarterFlow={isRestarterFlow}>
        <SwitchTransition>
          <CSSTransition
            classNames="form-page"
            timeout={pageTransitionSpeed}
            key={formState.page}
            nodeRef={nodeRef}
          >
            <>
              {formState.page === 1 && (
                <div ref={nodeRef}>
                  {/* The <div>s wrapping each <Page> are required to ensure the animation classes are correctly applied */}
                  <Page1
                    formState={formState}
                    setFormState={setFormState}
                    title={
                      isRestarterFlow
                        ? "Let's check it's you"
                        : 'Log in to your account'
                    }
                    noAccountPrompt="Don't have an account?"
                    signUpButtonText="Sign up"
                    finishLoggingIn={finishLoggingIn}
                  />
                </div>
              )}
              {formState.page === 2 && (
                <div ref={nodeRef}>
                  <Page2 formState={formState} setFormState={setFormState} />
                </div>
              )}
              {formState.page === 3 && (
                <div ref={nodeRef}>
                  <Page3
                    formState={formState}
                    setFormState={setFormState}
                    openMagicLinkInWebOnly={isRestarterFlow}
                  />
                </div>
              )}
              {formState.page === 4 && (
                <div ref={nodeRef}>
                  <Page4 formState={formState} setFormState={setFormState} />
                </div>
              )}
              {formState.page === 5 && (
                <div ref={nodeRef}>
                  <Page5
                    formState={formState}
                    setFormState={setFormState}
                    finishLoggingIn={finishLoggingIn}
                    isRememberMeCheckboxShown={!isRestarterFlow}
                  />
                </div>
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </LoginWrapper>
      <style jsx>{`
        /* Do not delete these next 4 styles - they are what animate the Login flow pages */
        .form-page-enter {
          opacity: 0;
          transform: scale(0.9);
        }

        .form-page-enter-active {
          opacity: 1;
          transform: translateX(0);
          transition:
            opacity ${pageTransitionSpeed}ms ease-in-out,
            transform ${pageTransitionSpeed}ms ease-in-out;
        }

        .form-page-exit {
          opacity: 1;
        }

        .form-page-exit-active {
          opacity: 0;
          transform: scale(0.9);
          transition:
            opacity ${pageTransitionSpeed}ms ease-in-out,
            transform ${pageTransitionSpeed}ms ease-in-out;
        }
      `}</style>
    </>
  );
};

export default Login;
