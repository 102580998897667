import { useState, useRef, ElementRef, FormEvent, ChangeEvent } from 'react';

import { FormStateType } from '@nextTypes/login';

import { BlueSubmitButton, BackButton } from '@common/buttons';

import { TextInput, ErrorMessage } from '@common/formElements';

import ButtonContainer from '@components/Login/ButtonContainer';
import UnderlinedLink from '@components/UnderlinedLink';

import { H3 } from '@common/headline';
import { BodyRegular, BodySmall } from '@common/body';
import { ErrorTracking } from '@common/errorTracking';
import { getAPIUrl } from '@nextUtils/urlUtils';

const initialLoginFormState = {
  password: '',
  rememberme: true,
};

type Props = {
  formState: FormStateType;
  setFormState: (arg0: FormStateType) => void;
  finishLoggingIn: () => Promise<void>;
  isRememberMeCheckboxShown?: boolean;
};

const Page5 = ({
  formState,
  setFormState,
  finishLoggingIn,
  isRememberMeCheckboxShown,
}: Props): JSX.Element => {
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
    null,
  );
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginFormData, setLoginFormData] = useState({
    ...initialLoginFormState,
    email: formState.email,
  });

  const submitButton = useRef<null | ElementRef<typeof BlueSubmitButton>>(null);

  const onLoginFormChange = (event: ChangeEvent<HTMLInputElement>) =>
    setLoginFormData({
      ...loginFormData,
      [event.target.name]:
        event.target.name === 'rememberme'
          ? event.target.checked
          : event.target.value,
    });

  const login = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoggingIn) {
      return;
    }

    if (loginFormData.password.length === 0) {
      if (submitButton.current) {
        submitButton.current.shakeButton();
      }
      setLoginErrorMessage('Please enter your password');
      return;
    }

    setIsLoggingIn(true);
    setLoginErrorMessage(null);

    try {
      const response = await fetch(`${getAPIUrl()}/api/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginFormData),
      });
      if (response.ok) {
        finishLoggingIn();
      } else {
        const responseMessage = await response.text();
        if (submitButton.current) {
          submitButton.current.shakeButton();
        }
        setLoginErrorMessage(responseMessage);
      }
    } catch (err) {
      ErrorTracking.track(err, {
        message: 'Error with login call',
      });
      if (submitButton.current) {
        submitButton.current.shakeButton();
      }
      setLoginErrorMessage(
        'Error with call. Please check your internet connection or contact our support team',
      );
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <>
      <div className="mb-8 text-center text-blue">
        <H3>Enter your password</H3>
      </div>
      <form onSubmit={login} noValidate>
        <div className="mb-4 text-center text-blue">
          <BodyRegular>
            <strong>Email: </strong>
            {loginFormData.email}
          </BodyRegular>
        </div>
        <TextInput
          type="password"
          name="password"
          placeholder="Password"
          value={loginFormData.password}
          onChange={onLoginFormChange}
          autoFocus
          required
        />

        {isRememberMeCheckboxShown && (
          <div className="my-[20px] flex items-center text-[14px]">
            <input
              className="mt-0 w-[21px]"
              type="checkbox"
              name="rememberme"
              id="rememberme"
              value={loginFormData.rememberme.toString()}
              defaultChecked
              onChange={onLoginFormChange}
            />
            <label htmlFor="rememberme">
              <BodySmall>This is a private computer</BodySmall>
            </label>
          </div>
        )}

        <ErrorMessage errorMessage={loginErrorMessage} />

        <ButtonContainer>
          <BackButton onClick={() => setFormState({ ...formState, page: 3 })}>
            Back
          </BackButton>
          <BlueSubmitButton ref={submitButton} isSubmitting={isLoggingIn}>
            Sign in
          </BlueSubmitButton>
        </ButtonContainer>
      </form>
      <div className="flex justify-center">
        <UnderlinedLink href={`/forgot?email=${formState.email}`} useLink>
          Forgot your password?
        </UnderlinedLink>
      </div>
    </>
  );
};

export default Page5;
